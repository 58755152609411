<template>
  <div>
    <div class="market-header">
      <div class="flex-between main container">
        <div class="flex-align">
          <img src="/static/icon/u_apps.svg">
          <div class="size-20" style="margin-left:10px">앱마켓</div>
        </div>
        <a :href="isLogin ? '/mypage/my_service' : '/signin'" target="_blank"
           v-if="$route.path.indexOf('app_market')===-1">
          <div class="main">내 서비스 바로가기</div>
        </a>
      </div>
    </div>
    <div class="flex" v-if="$route.path.indexOf('app_market')===-1 && isDetail" style="position:absolute;top:64px">
      <div class="unselect flex-align" style="padding:24px 0 24px 28px" @click="$router.back()"><img src="/static/icon/u_arrow-left.svg"> <div class="body2 main">목록으로 돌아가기</div></div>
    </div>
    <keep-alive :include="['Market']">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
  export default {
    name: "MarketMain",
    created() {

    },
    data() {
      return {
        isDetail: false,
      }
    },
    methods: {},
    watch: {
      $route(n) {
        this.isDetail = n.path.indexOf('detail')>-1 || n.path.indexOf('inquiry_reg')>-1;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  a
    color $main
  .market-header
    background-color #ffffff
    display flex
    align-items center
    height 70px
    position fixed
    top 0
    left 0
    width 100%
    z-index 10
    border-top 4px solid $main
    border-bottom 1px solid $gray1
</style>
